
export default class Plan {
  constructor (
    id,
    name, 
    duration, 
    status,
  ) {
    this.id = id
    this.name = name
    this.duration = duration
    this.status = status
  }
}